import {
  Box,
  Button,
  Container,
  fade,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core"
import { graphql } from "gatsby"
import { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import theme from "../config/theme"
import { ReactComponent as LeftArrow } from "../icons/left-arrow-sm.svg"
import { ReactComponent as RightArrow } from "../icons/right-arrow-sm.svg"
import { get } from "lodash"

const ResourceLogo = ({ type }) => {
  return (
    <Box
      position="absolute"
      height={74}
      width={74}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="100%"
      right="0"
      bottom="0"
      style={{
        transform: `translate3d(0,calc(50% + 10px),0)`,
        background: `linear-gradient(to bottom,  ${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 50%,#ffffff 50%)`,
      }}
    >
      <Box
        borderRadius="100%"
        bgcolor="#ffffff"
        width={54}
        height={54}
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ boxShadow: `0px 0px 10px rgba(0,0,0,.5)` }}
      >
        <img
          src={
            type === `Blog` || type === `Other`
              ? `/images/rr.svg`
              : `/social-icons/${type.toLowerCase()}.png`
          }
          width={34}
          height={34}
          alt={type}
        />
      </Box>
    </Box>
  )
}

const ResourcesPage = ({
  data: {
    allContentfulResource: { resources, totalCount },
  },
}) => {
  const [page, setPage] = useState(0)
  const limit = 9
  return (
    <Layout title="Resources">
      <Helmet>
        <script src="//apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <Box style={{ backgroundImage: `url(/images/circles-background.png)` }}>
        <Container>
          <Box py={1}>
            <Typography align="center" variant="h2" component="h1">
              Media Resource
            </Typography>
          </Box>
          <Grid container>
            {resources
              .slice(page * limit, page * limit + limit)
              .map((resource, i) => (
                <Grid
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  key={`resource-${i}`}
                  style={{ display: `flex`, alignItems: `stretch` }}
                >
                  <Box
                    component="a"
                    href={
                      resource.type === `Blog`
                        ? `/blog/${resource.slug}`
                        : resource.url
                    }
                    target={resource.type === `Blog` ? `` : `_blank`}
                    boxShadow={`0px 0px ${theme.spacing(1)} ${fade(
                      theme.palette.primary.main,
                      0.2
                    )}`}
                    mb={2}
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    style={{ textDecoration: `none` }}
                  >
                    <Box
                      position="relative"
                      borderBottom={`10px solid ${theme.palette.primary.main}`}
                      height={426}
                      style={{
                        backgroundImage: `url('${get(
                          resource,
                          `image.resize.src`
                        )}')`,
                        backgroundSize: `cover`,
                      }}
                    >
                      <ResourceLogo type={resource.type} />
                    </Box>
                    <Box
                      bgcolor={`white`}
                      p={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      flex="1"
                    >
                      <Typography gutterBottom align="center" variant="h3">
                        {resource.title}
                      </Typography>
                      <Button style={{ marginTop: `auto`, marginBottom: 0 }}>
                        Read more
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Box display="flex" justifyContent="flex-end" my={1}>
            <Box>
              <IconButton
                disabled={page === 0}
                onClick={() => setPage(page - 1)}
              >
                <LeftArrow />
              </IconButton>
              <IconButton
                disabled={
                  page === Math.ceil(totalCount / limit) ||
                  resources.length < limit
                }
                onClick={() => setPage(page + 1)}
              >
                <RightArrow />
              </IconButton>
            </Box>
          </Box>
          <Container maxWidth="lg">
            <Box mb={2}>
              <Typography variant="h3">Andrew's Instagram Feed</Typography>
            </Box>
            <div className="elfsight-app-ed64beeb-41c9-495b-a1e8-5c48d1d80a12"></div>
          </Container>
        </Container>
      </Box>
    </Layout>
  )
}

export default ResourcesPage

export const PageQuery = graphql`
  query Resources {
    allContentfulResource {
      resources: nodes {
        title
        type
        slug
        image {
          resize(
            resizingBehavior: FILL
            cropFocus: CENTER
            width: 377
            height: 426
            toFormat: JPG
          ) {
            src
          }
        }
        url
      }
      totalCount
    }
  }
`
